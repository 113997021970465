.filterMenu {
  margin-top: 12px;
  margin-bottom: 12px;
  white-space: normal;
}
.activeFilters {
  line-height: 2em;
  min-height: 44px;
  margin-top: 12px;
  margin-bottom: 12px;
  height: 100%;
  border-radius: 2px;
  padding: 9px;
  white-space: normal;
}

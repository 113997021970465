/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */


.react-calendar-heatmap .color-nobills {
  fill: #f6a209;
}
.react-calendar-heatmap .color-problematic {
  fill: #f60f0c;
}

.react-calendar-heatmap .color-1 {
  fill: #ecf6b1;
}

.react-calendar-heatmap .color-2 {
  fill: #b8f60e;
}
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f9f9f9;
}

.object-success {
  background-color: #f6ffed;
}

.object-warning {
  background-color: #fff1f0;
}

.projection {
  background-color: #f5f5f5;
}

.selected {
  background-color: #bae7ff;
}

.production_incoming {
  background-color: #bae7ff;
}

.production_warning {
  background-color: #ffe7ba;
}

.projection_actual {
  background-color: #fafafa;

}.projection_finish {
  background-color: #e8e8e8;
}

 .projection_highlight {
  background-color: #f6ffed;
}
 .projection_final {
  background-color: #f0f5ff
}

.projection_titlerow {
  font-weight: bold;
}

.projection_highlight_good {
  background-color: #f6ffed;
  font-weight: bold;
}

.projection_highlight_bad {
  background-color: #fff1f0;
  font-weight: bold;
}

.budget_titlerow {
  font-weight: bold;
  background-color: #feffe6;
}

.budget_costtyperow {
  font-weight: bold;
  background-color: #e6f7ff;
}

.right {
  text-align: right;
}

.mobileonly {
    display: none;
}

.fullscreenonly {
    display: none;
}

@media screen and (max-width: 600px) {
  .mobileonly {
    display: block !important;
  }
}

@media screen and (min-width: 600px) {
  .fullscreenonly {
    display: block !important;
  }
}

.pvtTable {overflow-y: scroll}

.pvtTable thead th {
  position: sticky;
  top: 0
}

.pvtTable tbody th {
  position: sticky;
  left: 0;
}


.pin1 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -20px;

  border-radius: 50% 50% 50% 0;
  border: 4px solid #ff0000;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background-color: #fff
}

.pin2 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -20px;
  min-height: 20px;
  min-width: 40px;
  border-radius: 8px 8px 8px 8px;
  border: 4px solid #333;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.pin3 {
  background-color: #333;
    border-radius: 10px;
    bottom: 11px;
    left: -10px;
    min-width: 20px;
    min-height: 20px;
    color: #fff;
    display: block;
    padding: 5px 10px;
    position: absolute;

}
.pin3::before {
    background-color: #333;
    display: block;
    content: ' ';
    position: absolute;
    width: 9px;
    height: 9px;
    bottom: -2px;
    left: 5px;
    transform:  rotate(45deg);
}

.smallOverviewTable .ant-card-body {
  padding: 0 !important;
}
